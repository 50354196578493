.Project {
  background: cadetblue;
  border-radius: 10px;
  color: black;
  margin: 20px;
  height: 167px;
  width: 250px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Project:hover {
  background: white;
}

.bg-image {
  opacity: 0.3;
  height: 167px;
  width: 250px;
  border-radius: 10px;
}

.Project h3 {
  position: absolute;
  padding: 30px;
}
